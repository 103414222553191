import React from "react";
import { FlatList } from "react-native";
import { Sucess, WarningOrange } from "@assets";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import {
  Container,
  AlertMessageContainer,
  AlertMessageText,
  Row,
  PickupPointsNoResults
} from "./PickupPoints.style";
import { IPickupPoint } from "@foodi/core";
import { PickupPoint } from "../PickupPoint";

interface IProps {
  pickupPoints?: IPickupPoint[];
  selectedPoint?: IPickupPoint;
  onClick?: (pickupPoint: IPickupPoint) => void;
  noResults?: boolean;
}

export const PickupPointsList: React.FC<IProps> = React.memo(({pickupPoints, selectedPoint, onClick, noResults}) => (
    <Container>
      {noResults ?
        <PickupPointsNoResults>{I18n.t("restaurantDetail.cart.pickupPointListNoResults")}</PickupPointsNoResults> :
        <FlatList
          style={{width: '100%'}}
          data={pickupPoints || []}
          maxToRenderPerBatch={2}
          renderItem={({item})=><PickupPoint data={item} onClick={onClick} isSelected={item.numericId === selectedPoint?.numericId} />}
        />
      }
      <AlertMessageContainer>
        {!!selectedPoint?.id ?
          <>
            <Row>
              <Sucess />
              <AlertMessageText>
                {` ${I18n.t("restaurantDetail.cart.pickupPointSelected")} ${selectedPoint.name}`}
              </AlertMessageText>
            </Row>

            {!selectedPoint.isSlotEnabled && <AlertMessageText>{selectedPoint.withdrawalSchedule}</AlertMessageText>}
          </>
          :
          <Row>
            <WarningOrange />
            <AlertMessageText>
              {` ${I18n.t("restaurantDetail.cart.pickupPointMustBeSelected")}`}
            </AlertMessageText>
          </Row>
        }
      </AlertMessageContainer>
    </Container>
  )
);
