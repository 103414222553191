"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FMarketingCardService = void 0;
const __1 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
class FMarketingCardService {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getMarketingCards(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const marketingCardsMapper = new mappers_1.MarketingCardsMapper();
                const response = yield this.apolloClient.query({
                    query: __1.GET_MARKETING_CARDS,
                    variables,
                });
                if (variables.callback)
                    variables.callback();
                return Promise.resolve(marketingCardsMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.MarketingCardErrors.GetMarketingCardsRequestError("GET_MARKETING_CARDS_REQUEST_FAILED", error));
            }
        });
    }
    getPublishedMarketingCardsNew(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const publishedMarketingCardsNewMapper = new mappers_1.PublishedMarketingCardsNewMapper();
                const response = yield this.apolloClient.query({
                    query: __1.GET_PUBLISHED_MARKETING_CARDS_NEW,
                    variables,
                });
                /* istanbul ignore next */
                if (variables.callback)
                    variables.callback();
                return Promise.resolve(publishedMarketingCardsNewMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.MarketingCardErrors.GetPublishedMarketingCardsNewRequestError("GET_PUBLISHED_MARKETING_CARDS_NEW_REQUEST_FAILED", error));
            }
        });
    }
    getBrand(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const brandMapper = new mappers_1.BrandMapper();
                const response = yield this.apolloClient.query({
                    query: __1.GET_BRAND,
                    variables,
                    fetchPolicy: 'network-only'
                });
                if (variables.callback)
                    variables.callback();
                return Promise.resolve(brandMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.MarketingCardErrors.GetBrandRequestError("GET_BRAND_REQUEST_FAILED", error));
            }
        });
    }
}
exports.FMarketingCardService = FMarketingCardService;
