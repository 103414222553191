import React, { useMemo } from "react";
import { StyleSheet, Dimensions } from "react-native";
import { ModalTemplate } from "@atomic/templates";
import { TestIDs } from "@utils";
import { PreferredZoneType } from "@foodi/core";
import { useDevices } from "@hooks";
import { PreferredZone, PreferredZoneProps } from "./PreferredZone";

interface PreferredZoneModalProps extends PreferredZoneProps{
	idHoldingView?: string;
	preferredZones: PreferredZoneType[];
  isOpen?: boolean;
  closeModal?: () => void;
  isForcedModal?: boolean
}

export const PreferredZoneModal = React.memo((props: PreferredZoneModalProps) => {
  const [isMobile] = useDevices();

  const styles = useMemo(() => _styles(isMobile), [isMobile]);

	return (
		<ModalTemplate
			forwardTestID={TestIDs.preferredZone.modals.container}
			forwardCloseTestID={TestIDs.preferredZone.actions.closeModal}
			style={styles.modalStyle}
      isHideCloseButton={props.isForcedModal}
			closeOnClickOutside
      isOpen={props.isOpen}
      handleClose={props.closeModal}
      bodyStyle={styles.modalBody}
		>
			<PreferredZone {...props} isFullWidth={false} />
		</ModalTemplate>
	);
});

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    modalStyle: {
      width: isMobile ? Dimensions.get("window").width : "auto",
      maxHeight: isMobile ? "100%" : 650,
      flexGrow: 0,
    },
    modalBody: {
      bottom: isMobile ? 0 : "auto",
      maxWidth: isMobile ? Dimensions.get("window").width : "50%",
      justifyContent: isMobile ? "flex-end" : "center",
    },
});

