"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_NEAR_HOLDINGS = void 0;
const client_1 = require("@apollo/client");
exports.GET_NEAR_HOLDINGS = client_1.gql `
  query nearHoldings(
    $searchText: String!
    $email: String
    $order: String
    $latitude: Float
    $longitude: Float
    $withDistance: Boolean!
    $after: Cursor
    $first: Int
  ) {
    nearHoldings(
      search: $searchText
      email: $email
      lat: $latitude
      lng: $longitude
      order: $order
      after: $after
      first: $first
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          distance @include(if: $withDistance)
          holding {
            id
            name
            foodi
            isBadgeRequired
            isScolaPassCashSystem
            address {
              zip
            }
            enabledPreferredZones
            maximumPreferredZones
            zones {
              id: numericId
              name
              status
            }
          }
        }
      }
    }
  }
`;
