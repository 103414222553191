import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Pressable, ScrollView, StyleSheet, Text, TextInput, View, TouchableOpacity } from "react-native";
import { unstable_batchedUpdates } from "react-dom";
import { Title13, Title16, Title22 } from "@stylesheets";
import { Button, FloatTextInput, HoldingRow, PreferredZone, LabelIconError } from "@atomic";
import { Information } from "@assets/icons/Information";
import { useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import { displayToastError, TestIDs } from "@utils";
import _ from "lodash";
import { Holding, HoldingView } from "@foodi/core";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { AccountThunks, HoldingThunks } from "@modules";
import { ErrorType } from "../../../apollo/errorHandler";
import { ToolTip, Modify } from "@assets";
import SecurityCodeProfileModal from "@atomic/molecules/SecurityCodeProfileModal";

interface IProps {
  handleClose: (update?: boolean) => void;
  currentHoldingView?: HoldingView;
  showPreferredZonesModal?: boolean;
  setShowPreferredZonesModal?: any;
}

export const HoldingViewModal: React.FC<IProps> = React.memo(
  ({ handleClose, currentHoldingView, showPreferredZonesModal, setShowPreferredZonesModal }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState<string>(
      currentHoldingView?.holding?.name || ""
    );
    const [holdings, setHoldings] = useState<Holding[]>([]);
    const [isHoldingsListShown, setHoldingsListShown] = useState<boolean>(
      false
    );
    const [selectedHolding, setSelectedHolding] = useState<Holding | undefined>(
      currentHoldingView?.holding
    );
    const [guestLastName, setGuestLastName] = useState<string | undefined>(
      currentHoldingView?.guest?.lastName || ""
    );
    const [serialNumber, setSerialNumber] = useState<string | undefined>(
      currentHoldingView?.guest?.supportSerialNumber ||
        currentHoldingView?.guest?.serialNumber ||
        ""
    );
    const [aliasValue, setAliasValue] = useState<string | undefined>(
      currentHoldingView?.alias || ""
    );
    const [haveBadgeData, setHaveBadgeData] = useState<boolean>(
      !!currentHoldingView?.guest?.lastName &&
        !!currentHoldingView?.guest?.serialNumber
    );
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isBadgeRequired, setBadgeRequired] = useState<boolean>(
      !!currentHoldingView?.holding?.isBadgeRequired
    );
    const [isScolapassCashSystem, setScolapassCashSystem] = useState<boolean>(
      !!currentHoldingView?.holding?.isScolaPassCashSystem
    );
    const [showToolTipMessage, setShowToolTipMessage] = useState<boolean>(
      false
    );

    const [securityCodeOpenModal, setSecurityCodeOpenModal] = useState<boolean>(
      false
    );

    const previousSerialNumber = _.get(currentHoldingView, 'guest.supportSerialNumber', null);
    const actualValidationCode = _.get(currentHoldingView, 'guest.validationCode', null);
    const [updateUserHoldingViewData, setUpdateUserHoldingViewData] = useState(false);

    const [enabledPreferredZones, setEnabledPreferredZones] = useState<boolean>(
      !!currentHoldingView?.holding?.enabledPreferredZones
    );
    const [maximumPreferredZones, setMaximumPreferredZones] = useState<number>(
      currentHoldingView?.holding?.maximumPreferredZones || 0
    );
    const [holdingZones, setHoldingZones] = useState<any[]>(
      currentHoldingView?.holding?.zones || []
    );
    const [preferredZones, setPreferredZones] = useState<any[]>(currentHoldingView?.preferredZones || []);
    const [selectedPrefZones, setSelectedPrefZones] = useState<any[]>(currentHoldingView?.preferredZones || []);
    const [hasPrefZonesError, setHasPrefZonesError] = useState<boolean>(false);

    const [modalHeight, setModalHeight] = useState<number>(0);

    const [
      userHoldingViewInputData,
      setUserHoldingViewInputData,
    ] = useState<any>({});

    useEffect(() => {
      if (searchValue && searchValue.length > 1 && !selectedHolding)
        getNearHoldingsDebounce(searchValue);
      else setHoldings([]);
    }, [searchValue]);

    useEffect(() => {
      setHoldingsListShown(holdings.length > 0);
    }, [holdings]);

    const getNearHoldingsDebounce = useCallback(
      _.debounce(getNearHoldings, 500),
      []
    );

    async function getNearHoldings(searchText: string) {
      const { holdingInfo }: any = await dispatch(
        AccountThunks.getNearHoldings({
          searchText: `${searchText}%`,
          withDistance: false,
          order: "name",
        })
      );

      setHoldings(holdingInfo);
    }

    const onChangeAlias = (text: string) => {
      setAliasValue(text);
    };

    const onChangeHolding = (text: string) => {
      setSelectedHolding(undefined);
      setSearchValue(text);
      unstable_batchedUpdates(() => {
        setSelectedHolding(undefined);
        setSearchValue(text);
        setBadgeRequired(false);
        setScolapassCashSystem(false);
        setHaveBadgeData(false);
        setSerialNumber("");
        setGuestLastName("");
        setSelectedPrefZones([]);
        setEnabledPreferredZones(false);
        setMaximumPreferredZones(0);
        setHoldingZones([]);
        setPreferredZones([]);
      });
    };

    const onChangeBadgeNumber = (text: string) => {
      setShowError(false);
      setErrorMessage('');
      if (isScolapassCashSystem && isNaN(+text)) {
        setShowError(true);
      }

      if (text !== "" && guestLastName !== "") {
        setHaveBadgeData(true);
        setUpdateUserHoldingViewData(true);
      } else {
        setHaveBadgeData(false);
      }
      setSerialNumber(text.trim());
    };

    const onChangeBadgeName = (text: string) => {
      setShowError(false);
      setErrorMessage('');
      if (text !== "" && serialNumber !== "") {
        setHaveBadgeData(true);
      } else {
        setHaveBadgeData(false);
      }
      setGuestLastName(text);
    };

    const onSelectHolding = (_selectedHolding: Holding) => {
      const hasEnabledPreferredZones = !!_selectedHolding?.enabledPreferredZones;

      if(hasEnabledPreferredZones){
        // when we change to a holding that has preferred zones we force to to show an error
        // so that the user knows he needs to change it
        setHasPrefZonesError(true);
      }

      unstable_batchedUpdates(() => {
        setSelectedHolding(_selectedHolding);
        setSearchValue(_selectedHolding.name?.trim() ?? "");
        setHoldingsListShown(false);
        setBadgeRequired(!!_selectedHolding?.isBadgeRequired);
        setScolapassCashSystem(!!_selectedHolding?.isScolaPassCashSystem);
        setEnabledPreferredZones(hasEnabledPreferredZones);
        setMaximumPreferredZones(_selectedHolding?.maximumPreferredZones || 0);
        setHoldingZones(_selectedHolding?.zones || []);
      });

    };

    const handleHoldingView = async () => {
      setLoading(true);

      if (
        !updateUserHoldingViewData &&
        (serialNumber === previousSerialNumber) &&
        actualValidationCode &&
        !didPreferredZonesChange()
      ) {
        setUserHoldingViewInputData({
          alias: aliasValue,
          //@ts-ignore
          idHolding: selectedHolding?.id,
          guestLastName,
          serialNumber,
          idHoldingView: currentHoldingView?.id,
          actualValidationCode,
          preferredZones: selectedPrefZones.map(eachZone => ({
            name: eachZone.name,
          })),
        });
        handleClose?.(true);
        return;
      }
      setGuestLastName(guestLastName);

      try {
        if (!!currentHoldingView) {
          const updateInput: any = {
            alias: aliasValue,
            //@ts-ignore
            idHolding: selectedHolding?.id,
            guestLastName,
            serialNumber,
            idHoldingView: currentHoldingView?.id,
            preferredZones: selectedPrefZones.map(eachZone => ({
              name: eachZone.name,
            })),
          }

          setUserHoldingViewInputData(updateInput);
          await dispatch(
            HoldingThunks.updateUserHoldingView(updateInput)
          );
        } else {
          const addInput: any = {
            alias: aliasValue,
            //@ts-ignore
            idHolding: selectedHolding?.id,
            guestLastName,
            serialNumber,
            preferredZones: selectedPrefZones.map(eachZone => ({
              name: eachZone.name,
            })),
          };
          setUserHoldingViewInputData(addInput);
          await dispatch(
            HoldingThunks.addUserHoldingView(addInput)
          );
        }

        handleClose?.(true);
      } catch (error) {
        if (error?.cause?.error?.message.includes("Validation code needed")) {
          setSecurityCodeOpenModal(true);
        } else {
          if (
            [
              ErrorType.BADGE_NOT_FOUND,
              ErrorType.USER_NOT_ASSOCIATED_WITH_HOLDING,
              ErrorType.USER_NOT_FOUND_IN_SCOLAPASS_DB,
            ].includes(error?.cause?.error.message)
          ) {
            setShowError(true);
            return;
          }

          if ([ErrorType.BADGE_EXPIRED, ErrorType.GUEST_MUST_BE_ENABLED].includes(error?.cause?.error.message)) {
            setShowError(true);
            setErrorMessage("account.expiredBadge");
            return;
          }
          displayToastError(dispatch);
        }
      } finally {
        setLoading(false);
      }
    };

    const checkBadgeInfo =
      (!serialNumber && !!guestLastName) || (!!serialNumber && !guestLastName);
    const buttonDisabled =
      checkBadgeInfo || !selectedHolding || hasPrefZonesError || showError || loading;

    const searchError =
      searchValue?.length > 2 &&
      holdings.length === 0 &&
      selectedHolding === undefined;
    
    const handleClosePrefZones = (prefZones: any[]) => {
      setSelectedPrefZones(prefZones);
      setShowPreferredZonesModal(false);
      if(prefZones.length > 0 && prefZones.length <= maximumPreferredZones){
        setHasPrefZonesError(false);
      }
    }

    const didPreferredZonesChange = () => {
      if (selectedPrefZones.length !== preferredZones.length) {
        return true;
      }

      let changedZonesCount = 0;

      selectedPrefZones.forEach(eachZone => {
        const foundIndex = preferredZones.findIndex(
          (eachPreferredZone: any) => eachPreferredZone.name == eachZone.name
        );
        if (foundIndex === -1) {
          changedZonesCount++;
        }
      });

      return changedZonesCount > 0;
    };
    
    const holdingViewInfo = () => (
      <View onLayout={({
        nativeEvent: {
          layout: { height },
        },
      }) => {
        setModalHeight(height);
      }}>
          <View style={styles.container}>
            <View style={styles.topContainer}>
              <View style={styles.textContainer}>
                  <View style={styles.alias}>
                      <Title22 isBlack>{I18n.t("account.alias")}</Title22>
                      <Text style={styles.optional}>
                          ({I18n.t("account.optional")})
                      </Text>
                  </View>
              </View>
              <View style={styles.listContainer}>
                <View style={styles.textHoldingInputContainer}>
                  <TextInput
                    testID={TestIDs.account.texts.aliasName}
                    style={styles.textInput}
                    onChangeText={onChangeAlias}
                    value={aliasValue}
                    placeholder={I18n.t("account.aliasPlaceholder")}
                  />
                </View>
              </View>
            </View>
            <View style={styles.topContainer}>
              <View style={styles.textContainer}>
                <Title22 isBlack>{I18n.t("account.establishment")}</Title22>
                <Title16>{I18n.t("account.consultServices")}</Title16>
              </View>
              <View style={styles.listContainer}>
                <View style={styles.textInputContainer1}>
                  <FloatTextInput
                    forwardTestID={TestIDs.account.texts.holdingName}
                    value={searchValue || ""}
                    onChangeValue={(value) => onChangeHolding(value)}
                    textPlaceHolder={I18n.t("account.establishmentName")}
                    hasError={searchError}
                    isEdit={!!currentHoldingView?.holding?.name}
                    errorMessage={I18n.t("account.invalidHolding")}
                  />
                </View>
                {isHoldingsListShown && (
                  <ScrollView style={styles.holdingList}>
                    {holdings.map((holding, index) => (
                      <HoldingRow
                        forwardTestID={`${TestIDs.account.actions.holdingRow}_${index}`}
                        key={holding.id}
                        holding={holding}
                        onSelectHolding={onSelectHolding}
                      />
                    ))}
                  </ScrollView>
                )}
              </View>
            </View>
            {enabledPreferredZones && (
                <View style={[styles.topContainer, styles.preferredZonesContainer]}>
                  <Title22 isBlack>
                    {selectedPrefZones.length === 1 ? I18n.t("preferredZones.title") : I18n.t("preferredZones.titlePlural")}
                  </Title22>
                  <View style={[styles.cardsContainer, selectedPrefZones.length > 0 && {flexDirection: 'row'}]}>
                    {
                      selectedPrefZones.length > 0 ?
                      (
                        selectedPrefZones.map((eachPreferredZone: {
                          id: string,
                          name: string,
                          }, 
                          index: number
                          ) => (
                          <View
                            key={`Filter_OffeZonesAndTypes_1_${index}`}
                            style={styles.zonesCard}
                          >
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="tail"
                              style={styles.zonesCardText}
                            >
                              {eachPreferredZone.name}
                            </Text>
                          </View>
                        ))
                      )
                      : (
                        <Title16>
                          {I18n.t('preferredZones.noZonesProfile')}
                        </Title16>
                      )
                    }
                    <TouchableOpacity
                        key={`Filter_PreferredZonesEdit`}
                        style={styles.modifyIconContainer}
                        onPress={() => setShowPreferredZonesModal(true)}
                      >
                        <Modify height={40} />
                    </TouchableOpacity>
                  </View>
                  {hasPrefZonesError && (
                    <View style={styles.errorContainer}>
                      <LabelIconError
                        title={I18n.t("preferredZones.errorZones")}
                        textStyle={styles.labelTextIconError}
                        style={styles.labelIconError}
                      />
                    </View>
                  )}
                </View>
            )}
            {isBadgeRequired ? (
              <View>
                <View style={styles.bottomContainer}>
                  <View style={styles.textContainer}>
                  <View style={styles.row}>
                    <Title22 isBlack>
                      {I18n.t(
                        isScolapassCashSystem
                          ? "account.scolapass.family"
                          : "account.badge"
                      )}
                    </Title22>
                      <View style={styles.toolTipContainer}>
                          <Pressable
                              testID={TestIDs.account.actions.tooltipIcon}
                              // @ts-ignore
                              onHoverIn={() => setShowToolTipMessage(true)}
                              onHoverOut={() => setShowToolTipMessage(false)}
                              // //for mobile responsive
                              onPress={() =>
                                  setShowToolTipMessage(!showToolTipMessage)
                              }
                          >
                              <ToolTip />
                          </Pressable>
                      </View>
                    </View>
                    <Title16>
                      {I18n.t(
                        isScolapassCashSystem
                          ? "account.scolapass.consultInvoices"
                          : "account.refillsAndOrders"
                      )}
                    </Title16>
                  </View>
                 {showToolTipMessage && (
                        <View
                            testID={TestIDs.account.views.tooltipMessage}
                            style={styles.tooltiptextContainer}
                        >
                            <Title13 style={styles.tooltiptext}>
                                {I18n.t(
                                    isScolapassCashSystem
                                        ? "account.scolapass.toolTipMessage"
                                        : "account.toolTipMessage"
                                )}
                            </Title13>
                        </View>
                  )}
                  <View>
                    <View style={styles.textInputContainer2}>
                      <FloatTextInput
                        forwardTestID={TestIDs.account.texts.badgeNumber}
                        errorForwardTestID={
                          TestIDs.account.views.badgeErrorMessage
                        }
                        value={serialNumber || ""}
                        onChangeValue={(value) => onChangeBadgeNumber(value)}
                        textPlaceHolder={I18n.t(
                          isScolapassCashSystem
                            ? "account.scolapass.familyFileNumber"
                            : "account.badgeNumber"
                        )}
                        hasError={checkBadgeInfo || showError}
                        isEdit={
                          !!currentHoldingView?.guest?.supportSerialNumber ||
                          !!currentHoldingView?.guest?.serialNumber
                        }
                      />
                    </View>
                      <View style={styles.textInputContainer1}>
                          <FloatTextInput
                              forwardTestID={TestIDs.account.texts.badgeName}
                              value={guestLastName || ""}
                              onChangeValue={(value) => onChangeBadgeName(value)}
                              textPlaceHolder={I18n.t(
                                  isScolapassCashSystem
                                      ? "account.scolapass.lastName"
                                      : "account.badgeName"
                              )}
                              hasError={checkBadgeInfo || showError}
                              isEdit={!!currentHoldingView?.guest?.lastName}
                              errorMessage={I18n.t(
                                  isScolapassCashSystem
                                      ? "account.scolapass.wrongFamilyAccount"
                                      : errorMessage || "account.wrongBadge"
                              )}
                          />
                      </View>
                  </View>
                </View>
                <View style={styles.bottomTextContainer}>
                  <View style={styles.bottomRow}>
                    <View style={styles.iconContainer}>
                          <Information/>
                    </View>
                    <Title13 style={styles.bottomText}>
                      {I18n.t(
                        isScolapassCashSystem
                          ? "account.scolapass.authorizationThirdParty"
                          : "account.badgeAuthorization"
                      )}
                    </Title13>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.margin} />
            )}
          </View>
          <Button
            forwardTestID={TestIDs.account.actions.signUpWithBadge}
            onPress={handleHoldingView}
            disabled={buttonDisabled}
            label={I18n.t("refill.validate")}
            loading={loading}
            styleButton={styles.firstButton}
          />
          <Pressable onPress={() => handleClose(false)}>
              <Text style={styles.cancel}>
                  {I18n.t(currentHoldingView ?  "account.cancelEdit" : "account.cancelAdd")}
              </Text>
          </Pressable>
          </View>
    );

    const compWithSecurityCheck = () => securityCodeOpenModal ? (
      <SecurityCodeProfileModal
        userHoldingViewInputData={userHoldingViewInputData}
        currentHoldingView={currentHoldingView}
        closeModal={() => setSecurityCodeOpenModal(false)}
        cancelClose={() => handleClose(false)}
        closeParentModal={(): void => handleClose?.(true)}
      />
    ) : holdingViewInfo()

    return (
      <View testID={TestIDs.account.views.holdingViewModalContainer}>
        {showPreferredZonesModal ?
          <PreferredZone 
            idHoldingView={currentHoldingView?.id}
            preferredZones={selectedPrefZones}
            zones={holdingZones}
            maximumPreferredZones={maximumPreferredZones}
            onClose={handleClosePrefZones}
            modalHeight={modalHeight}
            isFullWidth={false}
          />
        : compWithSecurityCheck()
        }
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
    StyleSheet.create({
        container: {
            flex: 1,
            alignItems: "center",
            width: isMobile ? "100%" : "auto",
            zIndex: 1,
        },
        topContainer: {
            marginTop: 20,
            zIndex: 2,
        },
        bottomContainer: {
            marginTop: 20,
        },
        textContainer: {
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        alias: {
            flexDirection: "row",
            alignItems: "flex-end"
        },
        optional: {
            marginLeft: 3,
            marginBottom: 4
        },
        bottomText: {
            color: Colors.foodiBlack,
            alignSelf: "center",
        },
        bottomTextContainer: {
            alignItems: "flex-start",
            marginBottom: 20,
            marginTop: 10,
        },
        bottomRow: {
            flexDirection: "row",
            marginBottom: 15,
        },
        row: {
            flexDirection: "row",
            alignItems: "center",
        },
        listContainer: {
            marginTop: 15,
            backgroundColor: Colors.white,
        },
        textHoldingInputContainer: {
            margin: 10,
            backgroundColor: Colors.white,
            height: 50,
            width: isMobile ? 320 : 350,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8,
            borderWidth: 1,
            borderColor: Colors.darkGrey,
        },
        textInputContainer1: {
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
        },
        textInputContainer2: {
            justifyContent: "center",
            alignItems: "center",
        },
        textInput: {
            height: 50,
            width: isMobile ? 320 : 350,
            fontSize: 16,
            color: Colors.foodiBlack,
            fontWeight: "400",
            borderRadius: 8,
            paddingHorizontal: 17,
            paddingTop: 15,
            paddingBottom: 14,
        },
        holdingList: {
            backgroundColor: Colors.white,
            borderWidth: 1,
            borderColor: Colors.border1,
            borderRadius: 8,
            paddingVertical: Spacing.M,
            width: isMobile ? 320 : 350,
            alignSelf: "center",
            position: "absolute",
            top: 65,
            maxHeight: 260,
            zIndex: 3,
        },
        firstButton: {
            marginBottom: 10,
            alignSelf: "center",
        },
        margin: {
            marginTop: 16,
        },
        toolTipContainer: {
            marginLeft: 5,
        },
        tooltiptextContainer: {
            fontFamily: "manrope",
            justifyContent: "center",
            width: isMobile ? responsiveSizeWidth(278) : 278,
            minWidth: 278,
            height: 93,
            backgroundColor: Colors.background1,
            textAlign: "left",
            padding: 5,
            position: "absolute",
            top: "15%",
            left: "80%",
            marginLeft: isMobile ? responsiveSizeWidth(-270) : -250,
            elevation: 100,
            zIndex: 100,
        },
        tooltiptext: {
            color: Colors.foodiBlack,
            marginHorizontal: 15,
        },
        iconContainer: {
            marginRight: 5,
        },
        cancel: {
            fontSize: 16,
            lineHeight: 17,
            textDecorationLine: "underline",
            color: '#090909',
            textAlign: "center",
        },
        preferredZonesContainer: {
          marginHorizontal: 60,
        },
        cardsContainer: {
          flexWrap: 'wrap',
        },
        zonesCard: {
          maxWidth: 200,
          height: 40,
          borderRadius: 8,
          borderWidth: 0.5,
          paddingVertical: 5,
          paddingHorizontal: 15,
          marginTop: 10,
          marginRight: 10,
          alignItems: 'center',
          justifyContent: 'center',
        },
        zonesCardText: {
          color: Colors.foodiBlack,
          fontFamily: "manrope",
          fontSize: 16,
          maxWidth: 200,
        },
        modifyIconContainer: {
          marginTop: 10,
        },
        errorContainer: {
          width: isMobile ? responsiveSizeWidth(340) : 360,
        },
        labelTextIconError: {
          fontSize: 13,
          textAlign: "left",
        },
        labelIconError: {
          marginLeft: isMobile ? 0 : 10,
          alignItems: "flex-start",
        },
    });
