import React, { useMemo } from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";
import { SimpleArrow } from "@assets";
import { Colors } from "@constants";
import { useDevices } from "@hooks";
import { Time } from "@atomic";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { PressableContainer, TextUnit } from "@atomic/molecules/DurationInput/DurationInput.style";

interface IProps {
  selected?: IListItem;
  options: IListItem[];
  onSelectedValue: (id: IListItem) => void;
}

interface IListItem {
  value: Time;
  label: string;
}

export const DurationSelect: React.FC<IProps> = React.memo(({
  selected,
  options,
  onSelectedValue
}) => {
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);

  const brandTheme = useSelector((state: GlobalState) => state.brandTheme?.brandTheme);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const selectedUnitValue = selected ?? options[0];

  const handleOpenDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelection = (id: IListItem) => {
    if (onSelectedValue) {
      onSelectedValue(id);
      setIsOpen(false);
    }
  };

  const handleOutOfFocus = () => {
    setIsOpen(false);
  };

  return <View style={styles.page}>
    <Pressable
      onPress={() => {
        handleOpenDropdown();
      }}>
      <View style={[styles.placeholder, isOpen && styles.placeholderWithOpenSelect]}>
        <Text>{selectedUnitValue.label}</Text>
      </View>
      <View style={[styles.iconView, isOpen && styles.iconTransform]}>
        <SimpleArrow />
      </View>
    </Pressable>
    {isOpen && <View style={[styles.selectContainer, isOpen && styles.selectContainerOpen]}>
      {options.map((o, index) => {
        return <PressableContainer
          key={o.value}
          isSelected={selectedUnitValue.value === o.value}
          brandColor={brandTheme?.buttonActionColors?.backgroundColor}
          isFirst={index === 0}
          isLast={index === options.length - 1}
          onPress={() => {
            handleSelection(o);
          }}
        >
          <TextUnit isSelected={selectedUnitValue.value === o.value} brandColor={brandTheme?.buttonActionColors?.textColor}>{o.label}</TextUnit>
        </PressableContainer>
      })}
    </View>}
  </View>;
});

const _styles = (noMargin: boolean, isMobile?: boolean, hasError?: boolean) =>
  StyleSheet.create({
    page: {
      width: 125
    },
    iconView: {
      position: 'absolute',
      right: '12px',
      top: '16px'
    },
    iconTransform: {
      transform: [{ rotate: '180deg' }]
    },
    selectContainer: {
      border: '1px solid black',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopColor: 'transparent'
    },
    selectContainerOpen: {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8
    },
    placeholder: {
      height: 40,
      fontSize: 14,
      color: Colors.foodiBlack,
      fontWeight: "600",
      borderRadius: 8,
      padding: 10,
      fontFamily: 'manrope',
      border: '1px solid black',
      textAlign: 'left',
    },
    placeholderWithOpenSelect: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    textInput: {
      height: 40,
      width: 60,
      fontSize: 18,
      color: Colors.foodiBlack,
      fontWeight: "600",
      borderRadius: 8,
      padding: 10,
      fontFamily: 'manrope-regular',
      border: '1px solid black'
    },
  });
