import style from "styled-components/native";
import { Colors } from "@constants";

export const CleaningTimeLabel = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 10px
    text-align: center;
`;

export const Container = style.View`
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;

export const PressableContainer = style.Pressable<{
  isSelected: boolean;
  brandColor?: string;
  isFirst: boolean;
  isLast: boolean;
}>`
  height: 40px;
  /*width: 125px;*/
  font-size: 14px;
  font-weight: 600;
  font-family: manrope;
  text-align: left;
  padding: 10px;
  textAlign: 'left',
  margin-top: ${({ isFirst }) => isFirst ? -1 : 0}px
  border-bottom-left-radius: ${({ isLast, isSelected }) => isLast && isSelected ? 4 : 0}px;
  border-bottom-right-radius: ${({ isLast, isSelected }) => isLast && isSelected ? 4 : 0}px;
  background-color: ${({ isSelected, brandColor }) =>  isSelected ? brandColor : null};
`;

export const TextUnit = style.Text<{
  isSelected: boolean;
  brandColor?: string;
}>`
  color: ${({ isSelected, brandColor }) => isSelected ? brandColor : Colors.foodiBlack};
`;

export const WarningMessageContainer = style.View`
    flex-direction: row;
    margin-top: 15px;
`;

export const WarningMessage = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 6px;
    max-width: 195px;
`;
