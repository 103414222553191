import { Dispatch } from "react";
import { I18n } from "react-redux-i18n";
import { FiltersActions, AtSiteTakeAwayType, ZonesType, RestaurantCardViewModel } from "@modules"
import { IPos, OfferTemplateWithdrawalType } from "@foodi/core";
import { getNumericId } from "@utils";

export type FiltersType = {
  type: string;
  value: string;
}

export enum FILTERTYPES {
  OFFERTYPE = "offerType",
  ZONE = "posZone",
  POSTYPE = "posTypeName"
}

export enum OFFERTYPES {
  EATIN = "Sur place",
  TAKEOUT = "À emporter"
}
export class FiltersViewModel {
  private eatInPosTypes = [AtSiteTakeAwayType.AT_SITE, AtSiteTakeAwayType.AT_SITE_TAKE_AWAY];
  private eatInOfferTemplateTypes = [
    OfferTemplateWithdrawalType.POS_AT_SITE,
    OfferTemplateWithdrawalType.POS_CLICK_SERVE,
    OfferTemplateWithdrawalType.TABLE_SERVICE,
  ];
  private takeOutPosTypes = [
    AtSiteTakeAwayType.TAKE_AWAY,
    AtSiteTakeAwayType.AT_SITE_TAKE_AWAY,
  ];
  private takeOutOfferTemplateTypes = [
    OfferTemplateWithdrawalType.CONNECTED_LOCKERS,
    OfferTemplateWithdrawalType.POS_TAKE_AWAY,
  ];

  setOfferType(posCard: IPos, typesSet: Set<string>){
    const eatInText = I18n.t("homepage.restaurantCard.eatIn");
    const takeOutText = I18n.t("homepage.restaurantCard.takeOut");
  
    let isEatIn = this.eatInPosTypes.includes(posCard.atSiteTakeAwayType as AtSiteTakeAwayType);
    let isTakeOut = this.takeOutPosTypes.includes(posCard.atSiteTakeAwayType as AtSiteTakeAwayType);
  
    posCard.activeOfferTemplates?.forEach((eachOffer) =>{
      if(!isEatIn){
        isEatIn = this.eatInOfferTemplateTypes.includes(eachOffer.withdrawalType);
      }
      if(!isTakeOut){
        isTakeOut = this.takeOutOfferTemplateTypes.includes(eachOffer.withdrawalType);
      }
    })
  
    if (isEatIn && isTakeOut){
      typesSet.add(eatInText);
      typesSet.add(takeOutText);
    } else {
      typesSet.add(isEatIn ? eatInText : takeOutText);
    }
  }

  setFiltersFromCards(
    cards: IPos[],
    filters: any,
    dispatch: Dispatch<any>
  ) {
    dispatch(FiltersActions.setInitFilters(cards));

    const offerTypesMapper: any = {
      'SurPlace': I18n.t("homepage.restaurantCard.eatIn"),
      'TakeAway': I18n.t("homepage.restaurantCard.takeOut")
    }
  
    dispatch(FiltersActions.setFilterState({
      offerTypes: filters?.offerTypes?.map((eachOfferType: string) => ({type: eachOfferType, value: offerTypesMapper[eachOfferType]})),
      zones: filters?.zones?.map(({name}: {name: string}) => ({name})),
      posTypes: filters?.posTypes
    }));
  }

  emptyFilter({
    cards,
    dispatch
  }: {
    cards: IPos[],
    dispatch: Dispatch<any>
  }){
    dispatch(FiltersActions.setFilteredPosCards(cards))

  }

  async filterCards({
    filters,
    dispatch,
    shouldInitFilters = false
  }: {
    filters: FiltersType[],
    dispatch: Dispatch<any>,
    shouldInitFilters?: boolean
  }){

    const restaurantCardViewModel = new RestaurantCardViewModel();

    const { posCards, defaultFilters } = await restaurantCardViewModel.getPosFiltered({dispatch, filters});

    if(shouldInitFilters){
      this.setFiltersFromCards(posCards, defaultFilters, dispatch);
    }

    const existingFilters: any[] = [];

    filters.forEach(eachFilter => {
      if(eachFilter.type !== "posZone"){
        existingFilters.push(eachFilter)
      }

      const filterZoneExists = defaultFilters?.zones?.some(({name}: {name: string}) => name === eachFilter.value);

      if(filterZoneExists){
        existingFilters.push(eachFilter)
      }
    });

    dispatch(FiltersActions.setFilters(existingFilters))

    dispatch(FiltersActions.setFilteredPosCards(posCards));

    return posCards;
  }

  findFilterIndex({
    selectedFilters,
    type,
    value
  }: {
    selectedFilters: FiltersType[],
    type: string,
    value: string
  }){
    const newValue = { type, value };

    return selectedFilters.findIndex(
      filter => filter.value === newValue.value && filter.type === newValue.type
    );
  }

  preSelectFromPreferredZones({
    preferredZones = [],
    dispatch
  }: {
    preferredZones: ZonesType[],
    dispatch: Dispatch<any>
  }){

    const preferredZonesFilters = preferredZones.map((eachPreferredZones:ZonesType ) => ({
      type: FILTERTYPES.ZONE,
      value: eachPreferredZones.name
    }));

    return this.filterCards({filters: preferredZonesFilters, dispatch, shouldInitFilters: true});

  }

}
