import React from "react";
import _ from "lodash";
import {
  PickupPointContainer,
  PointName,
  PointSchedule,
  PointDescription
} from "./PickupPoint.style";
import { IPickupPoint } from "@foodi/core";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { Colors } from "@constants";

interface IProps {
  data: IPickupPoint;
  isSelected?: boolean;
  onClick?: (pickupPoint: IPickupPoint) => void;
  margin?: 'top' | 'bottom'
}

export const PickupPoint: React.FC<IProps> = React.memo(({data, isSelected, onClick, margin = 'top'}) => {

  const brandTheme = useSelector(
    (state: GlobalState) => state.brandTheme.brandTheme
  );

  const backgroundColor = isSelected
    ? brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault
    : undefined;

  const color = isSelected
    ? brandTheme.buttonActionColors?.textColor || Colors.white
    : Colors.foodiBlack

  return (
    <PickupPointContainer margin={margin} backgroundColor={backgroundColor} onPress={()=> onClick && onClick(data)}>
      <PointName color={color}>{data.name}</PointName>
      {!data.isSlotEnabled && <PointSchedule color={color}>{data.withdrawalSchedule}</PointSchedule>}
      <PointDescription color={color}>{data.description}</PointDescription>
    </PickupPointContainer>)
  })
