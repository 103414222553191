import { Sucess, WarningOrange } from "@assets";
import {
  ISlots,
  OffersActions,
  OfferViewModel,
} from "@modules";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import React from "react";
import { FlatList } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  OFFER_SLOT_GAP,
  Container,
  Title,
  Description,
  Slot,
  SlotText,
  WarningMessage,
  WarningMessageContainer,
  LocalDateContainer,
} from "./PickupSlots.style";
import { useDevices } from "@hooks";
import { Colors } from "@constants";

interface IProps {
  slots: { withdrawRange: string; }[];
  isRefillFromCart?: boolean;
  isRoomService?: boolean;
}

const OFFER_SLOT_LIST_COLUMNS = 3;

export const PickupSlots: React.FC<IProps> = ({
  slots: pickupSlots,
  isRefillFromCart,
  isRoomService,
}) => {
  const dispatch = useDispatch();
  const [isMobile] = useDevices();

  const pointOfSaleName = useSelector(
    (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale?.name
  );
  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );
  const backgroundColor = useSelector(
    (state: GlobalState) =>
      state.brandTheme?.brandTheme?.buttonActionColors?.backgroundColor
  );
  const textColor = useSelector(
    (state: GlobalState) =>
      state.brandTheme?.brandTheme?.buttonActionColors?.textColor
  );
  const offerSlot = useSelector(
    (state: GlobalState) => state.offers?.selectedOfferSlot
  );
  const selectedDay = useSelector(
    (state: GlobalState) => state.booking?.selectedDay ?? 0
  );
  const cartCurrentInfo = useSelector(
    (state: GlobalState) => state.pointOfSale?.cartCurrentInfo
  );

  const [selectedItemIndex, setSelectedItemIndex] = React.useState<
    number | undefined
  >();

  const [warningMessage, setWarningMessage] = React.useState<string>();

  const offerVM = new OfferViewModel();

  const date = offerVM.getDateDescription(selectedDay, userLanguage);

  const slots = offerVM.getCurrentPickupSlots(pickupSlots);

  const handleSlotSelection = (item: any, index: number) => () => {
    dispatch(OffersActions.setSelectedOfferSlot(item));
    setSelectedItemIndex(index);
  };

  React.useEffect(() => {
    cartCurrentInfo?.offerSlots?.findIndex((s: ISlots, idx: number) => {
      const res =
        s?.withdrawRange === cartCurrentInfo?.offerSlot?.withdrawRange;
      if (res) setSelectedItemIndex(idx);
      return res;
    });
  }, [isRefillFromCart, cartCurrentInfo]);

  React.useEffect(() => {
    if (!offerSlot && !isRefillFromCart) setSelectedItemIndex(undefined);
  }, [selectedDay]);

  const currentOfferSlot =
    isRefillFromCart && !offerSlot ? cartCurrentInfo.offerSlot : offerSlot;

  React.useEffect(() => {
    if (currentOfferSlot) {
      const slot = isRoomService ? offerSlot?.initSlot : `${offerSlot?.initSlot}-${offerSlot?.endSlot}`;
      setWarningMessage(`${I18n.t("restaurantDetail.cart.youChose")}: ${slot}`);
    }
    else {
      setWarningMessage(I18n.t("restaurantDetail.cart.chooseSlot"));
    }
  }, [currentOfferSlot, offerSlot]);

  const renderItem = ({ item, index }: any) => {
    const hasGap = (index + 1) % OFFER_SLOT_LIST_COLUMNS !== 0;

    const textSlotColor =
      selectedItemIndex === index
        ? textColor || Colors.foodiBlack
        : Colors.foodiBlack;

    return (
      <Slot
        testID={`${TestIDs.restaurantDetail.views.offerSlot}_${index}`}
        hasGap={hasGap}
        onPress={handleSlotSelection(item, index)}
        isSelected={selectedItemIndex === index}
        brandColor={backgroundColor || Colors.foodiDefault}
        isMobile={isMobile}
      >
        <SlotText bold textColor={textSlotColor}>
          {item.initSlot}
        </SlotText>
        {!isRoomService && <SlotText textColor={textSlotColor}>{item.endSlot}</SlotText>}
      </Slot>
    );
  };

  const showList = !!slots?.length;

  const showIcon = selectedItemIndex !== undefined && currentOfferSlot && !!slots?.length;

  return (
    <Container testID={TestIDs.restaurantDetail.views.offerSlotsContainer}>
      <Title>{isRoomService ? I18n.t('restaurantDetail.cart.pickupTimePickUpRoomService') : I18n.t('restaurantDetail.cart.pickupTime')}</Title>
      <LocalDateContainer>
        <Description isDate>{date}</Description>
        <Description>{pointOfSaleName}</Description>
        <WarningMessageContainer>
          <WarningOrange/>
          <WarningMessage>{isRoomService ? I18n.t("restaurantDetail.cart.pickUpSlotWarningRoomService") : I18n.t("restaurantDetail.cart.pickUpSlotWarning")}</WarningMessage>
        </WarningMessageContainer>
      </LocalDateContainer>
      {showList && (
        <FlatList
          columnWrapperStyle={{
            marginBottom: OFFER_SLOT_GAP,
          }}
          data={slots}
          renderItem={renderItem}
          keyExtractor={(item: any) => item?.initSlot}
          numColumns={OFFER_SLOT_LIST_COLUMNS}
        />
      )}

      <WarningMessageContainer>
        {showIcon ? <Sucess /> : <WarningOrange />}
        <WarningMessage>{warningMessage}</WarningMessage>
      </WarningMessageContainer>
    </Container>
  );
};
