import { Sucess, WarningOrange } from "@assets";
import { OfferViewModel } from "@modules";
import React, { useMemo, useState } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { Colors } from "@constants";
import { CleaningTimeLabel, Container, WarningMessage, WarningMessageContainer } from "@atomic/molecules/DurationInput/DurationInput.style";
import { DurationSelect } from "@atomic/molecules/DurationInput/DurationSelect";

export enum Time {
  MINUTES = 'minutes',
  HOURS = 'hours'
}

interface IProps {
  options: Time[];
  setEventDuration: (eventDuration: { unit: Time, duration: number } | null) => void
}

export const DurationInput: React.FC<IProps> = ({ options, setEventDuration }) => {
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);

  const [offerVM] = useState(new OfferViewModel());

  const optionsComposed = options.map((option, index) => ({
    value: option,
    label: I18n.t(`common.${option}`)
  }));

  const [duration, setDuration] = useState('');
  const [unit, setUnit] = useState(optionsComposed[0]);

  const trimNumberAgainstMaximumAllowedValue = (v: number) => {
    // maximum allow is 2 days - 48 hours or 2880 minutes
    const maximumValueAllowed = unit.value === 'hours' ? 48 : 2880;
    while (v > maximumValueAllowed) {
      v = Math.floor(v / 10);
    }
    return v;
  }

  const onChangeDuration = (v: string) => {
    if (v === '' || v === '0') {
      setDuration('');
      setEventDuration(null);
      return;
    }
    const value = parseInt(v);
    const isValidNumber = !isNaN(value);
    if (isValidNumber) {
      const val = trimNumberAgainstMaximumAllowedValue(value);
      setDuration(val.toString());
      setEventDuration({ unit: unit.value, duration: val});
    }
  };

  const onChangeUnit = (v: { value: Time, label: string }) => {
    setUnit(v);
    if (duration) {
      setEventDuration({ unit: v.value, duration: parseInt(duration) });
    }
  }

  const formatDuration = () => {
    const durationObj = offerVM.convertToDurationObject({ unit: unit.value, duration});
    return durationObj.toFormat("hh:mm");
  }

  const showSuccessIcon = duration !== '';
  const warningMessage = duration === '' ? I18n.t("restaurantDetail.cart.chooseCleaningSlot") :
    `${I18n.t("restaurantDetail.cart.cleaningSlotSelected")}: ${formatDuration()}`;

  return (
    <Container>
      <CleaningTimeLabel>{I18n.t("restaurantDetail.cart.cleaningTimeSlot")}</CleaningTimeLabel>
      <View style={{ flex: 1, flexDirection: 'row', marginTop: 10 }}>
        <TextInput
          style={[styles.textInput]}
          onChangeText={onChangeDuration}
          keyboardType="numeric"
          value={duration}
          selection={{ start: duration.length }}
          editable={true}
        />
        <DurationSelect
          selected={unit}
          options={optionsComposed}
          onSelectedValue={onChangeUnit}
        />
      </View>
      <WarningMessageContainer>
        {showSuccessIcon ? <Sucess /> : <WarningOrange />}
        <WarningMessage>{warningMessage}</WarningMessage>
      </WarningMessageContainer>
    </Container>
  );
};


const _styles = (noMargin: boolean, isMobile?: boolean, hasError?: boolean) =>
  StyleSheet.create({
    container: {
      margin: noMargin ? 0 : 10,
      marginBottom: 10,
      backgroundColor: Colors.white,
      height: 50,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: hasError ? Colors.redError : Colors.darkGrey,
    },
    textInput: {
      height: 40,
      width: 60,
      fontSize: 16,
      color: Colors.foodiBlack,
      borderRadius: 8,
      padding: 10,
      fontFamily: 'manrope',
      border: '1px solid black',
      marginRight: 10,
      textAlign: 'center'
    },
    textPlaceHolder: {
      position: "absolute",
      left: 14,
      color: Colors.darkGrey,
      fontFamily: 'manrope-regular'
    },
    iconContainer: {
      position: "absolute",
      width: 25,
      height: 20,
      marginRight: 10,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      right: 0,
      color: Colors.darkGrey,
    },
    labelIconError: {
      marginLeft: isMobile ? 0 : 10,
      alignItems: "flex-start",
      ...( noMargin ? { justifyContent: "flex-start" } : {})
    },
    labelTextIconError: {
      fontSize: 13,
      textAlign: "left",
    },
  });
