"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_OFFER = void 0;
const client_1 = require("@apollo/client");
exports.GET_OFFER = client_1.gql `
query getOffer($idOffer: ID!) {
  offer: get(id: $idOffer) {
    ... on Offer {
      id
      orderRange
      published
      withdrawRange
      isRoomService
      menus {
        day
        elements {
          id
          label
          idElement
          price {
            amount
            currency
          }
          allergens
          certifications
          products(main: false) {
            id
            label
            certifications
          }
          dish {
            id
            dishGroup {
              id
            }
          }
        }
      }
      offerItems(
            limit: 500, 
            querySearch: [
                { 
                    key: "toSell"
                    operator: "="
                    value: "ACTIVATED"
                }
            ]
        ) {
        id
        localArticle {
            id
            article {
                id
                idElement
            }
        }
        quantityPurchasable
        quantityRemaining
        quantityOverall
        baking
        articleTags {
          id
          numericId
          label
        }
        articleCertifications {
          id
          numericId
          label
        }
        inheritedLabel
        inheritedFamily
        inheritedImage
        inheritedDescription
        inheritedPrice {
          amount
          currency
        }
        container {
          id
          label
          price {
            amount
            currency
          }
        }
      }
      offerItemsFormula {
          id
          numericId
          idOffer
          localArticle {
            id
            label
            article {
              label
              description
              idElement
              image_v2 {
                id
                path
              }
            }
          }
          baking
          container {
            label
            price {
              amount
              currency
            }
          }
          quantityOverall
          quantityRemaining
          quantityPurchasable
          formulaTemplate {
            id
            name
            description
            image {
              path
            }
            steps {
              name
              order
              maxProducts
            }
            price {
              amount
              currency
            }
          }
          steps {
            name
            order
            maxProducts
          }
          stepNumber
          idOfferItemFormulaParent
          isFormulaComplete
          created
          updated
        }
    }
  }
}
`;
