import React from "react";

export const SimpleArrow: React.FC = () => (
  <svg width="16" height="9" viewBox="0 0 16 9" xmlns="http://www.w3.org/2000/svg">
    <polygon
      fill={'grey'}
      points="1.390625 0.296875 8 6.94140625 14.609375 0.296875 15.453125 1.140625 8.421875 8.171875 8 8.5234375 7.578125 8.171875 0.546875 1.140625"
    />
  </svg>
);

