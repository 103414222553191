import { Sucess, WarningOrange } from "@assets";
import { BookingOrder, OfferSlot } from "@foodi/core";
import {
  BookingOffers,
  CalendarViewModel,
  ISlots,
  OffersActions,
  OfferViewModel,
  OrderViewModel,
} from "@modules";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import _ from "lodash";
import React from "react";
import { FlatList } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  OFFER_SLOT_GAP,
  Container,
  Title,
  Description,
  Slot,
  SlotText,
  WarningMessage,
  WarningMessageContainer,
  LocalDateContainer,
  RemainingPlaceText,
} from "./OfferSlots.style";
import { useDevices } from "@hooks";
import { Colors } from "@constants";

interface IProps {
  offerSlots: Omit<OfferSlot, "__typename">[];
  isModify?: any;
  selectedBookingOffer?: BookingOffers;
  bookingForDifferentPos?: BookingOrder;
  isRefillFromCart?: boolean;
  isRoomService?: boolean;
}

const OFFER_SLOT_LIST_COLUMNS = 3;
const MINIMUM_REMAINING_ORDERS_PER_SLOT = 20;

export const OfferSlots: React.FC<IProps> = ({
  offerSlots,
  selectedBookingOffer,
  bookingForDifferentPos,
  isRefillFromCart,
  isRoomService
}) => {
  const dispatch = useDispatch();
  const [isMobile] = useDevices();

  const pointOfSaleName = useSelector(
    (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale?.name
  );
  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );
  const backgroundColor = useSelector(
    (state: GlobalState) =>
      state.brandTheme?.brandTheme?.buttonActionColors?.backgroundColor
  );
  const textColor = useSelector(
    (state: GlobalState) =>
      state.brandTheme?.brandTheme?.buttonActionColors?.textColor
  );
  const offerSlot = useSelector(
    (state: GlobalState) => state.offers?.selectedOfferSlot
  );
  const isBookingSelected = useSelector(
    (state: GlobalState) => state.booking?.isBookingSelected
  );
  const selectedDay = useSelector(
    (state: GlobalState) => state.booking?.selectedDay ?? 0
  );
  const bookingOrders = useSelector(
    (state: GlobalState) => state.booking?.bookingOrders
  );
  const cartCurrentInfo = useSelector(
    (state: GlobalState) => state.pointOfSale?.cartCurrentInfo
  );

  const [selectedItemIndex, setSelectedItemIndex] = React.useState<
    number | undefined
  >();

  const [changeSelection, setChangeSelection] = React.useState<boolean>(false);

  const [warningMessage, setWarningMessage] = React.useState<string>();

  const offerVM = new OfferViewModel();

  const orderVM = new OrderViewModel(dispatch, "");

  const [CalendarVM] = React.useState<CalendarViewModel>(
    new CalendarViewModel()
  );

  const date = offerVM.getDateDescription(selectedDay, userLanguage);

  const slots = offerVM.getCurrentSlots(offerSlots);

  const handleChangeSelection = (item: any) => {
    const offer = bookingOrders?.find(
      (b: BookingOrder) => b?.withdrawRange === item?.withdrawRange
    );
    setChangeSelection(!offer);
  };

  const handleSlotSelection = (item: any, index: number) => () => {
    handleChangeSelection(item);
    dispatch(OffersActions.setSelectedOfferSlot(item));
    setSelectedItemIndex(index);
  };

  React.useEffect(() => {
    cartCurrentInfo?.offerSlots?.findIndex((s: ISlots, idx: number) => {
      const res =
        s?.withdrawRange === cartCurrentInfo?.offerSlot?.withdrawRange;
      if (res) setSelectedItemIndex(idx);
      return res;
    });
  }, [isRefillFromCart, cartCurrentInfo]);

  React.useEffect(() => {
    if (!offerSlot && !isRefillFromCart) setSelectedItemIndex(undefined);
  }, [selectedDay]);

  const currentOfferSlot =
    isRefillFromCart && !offerSlot ? cartCurrentInfo.offerSlot : offerSlot;

  React.useEffect(() => {
    const warningMessageText = offerVM.getWarningMessage(
      currentOfferSlot,
      selectedItemIndex,
      changeSelection,
      selectedBookingOffer?.fullyBooked,
      !selectedBookingOffer?.published,
      !!slots?.length,
      !!bookingForDifferentPos,
      isBookingSelected,
      isRoomService
    );
    setWarningMessage(warningMessageText);
  }, [
    offerSlot,
    selectedItemIndex,
    changeSelection,
    selectedBookingOffer,
    slots,
    bookingForDifferentPos,
    selectedDay,
    isBookingSelected,
    isRefillFromCart,
    cartCurrentInfo,
  ]);

  React.useEffect(() => {
    if (!offerSlot || selectedItemIndex === undefined) {
      const { dayNumber } = CalendarVM.getDateInfo(
        selectedDay,
        userLanguage || "fr"
      );
      const reservation = orderVM.getReservationForSpecificDay(
        bookingOrders,
        dayNumber
      );

      if (reservation) {
        slots.findIndex((s: ISlots, idx: number) => {
          const res = s?.withdrawRange === reservation?.withdrawRange;
          if (res) {
            dispatch(OffersActions.setSelectedOfferSlot(s));
            handleChangeSelection(s);
            setSelectedItemIndex(idx);
          }
          return res;
        });
      }
    }
  }, [bookingOrders, slots, selectedDay, selectedItemIndex]);

  const renderItem = ({ item, index }: any) => {
    const hasGap = (index + 1) % OFFER_SLOT_LIST_COLUMNS !== 0;
    const notSelectableAt =
      item?.withdrawRange === offerSlot?.withdrawRange
        ? false
        : !item?.selectableAt;

    const textSlotColor =
      selectedItemIndex === index
        ? textColor || Colors.foodiBlack
        : Colors.foodiBlack;

    const maxOrdersPerSlot = selectedBookingOffer?.maxOrdersPerSlot;

    const remainingSeats = maxOrdersPerSlot
      ? maxOrdersPerSlot - item.numOrders
      : 0;

    const remainingSeatsLabel = I18n.t(
      remainingSeats === 1
        ? "restaurantDetail.cart.remainingSeat"
        : "restaurantDetail.cart.remainingSeats",
      {
        remainingSeats,
      }
    );

    const noRemaingSeatsLabel =  I18n.t("restaurantDetail.cart.noRemainingSeats");

    return (
      <Slot
        testID={`${TestIDs.restaurantDetail.views.offerSlot}_${index}`}
        hasGap={hasGap}
        disabled={notSelectableAt}
        onPress={handleSlotSelection(item, index)}
        isSelected={selectedItemIndex === index}
        brandColor={backgroundColor || Colors.foodiDefault}
        notSelectableAt={notSelectableAt}
        isMobile={isMobile}
        isBooking={isBookingSelected}
      >
        <SlotText bold textColor={textSlotColor}>
          {item.initSlot}
        </SlotText>
        {!isRoomService && <SlotText textColor={textSlotColor}>{item.endSlot}</SlotText>}
        {maxOrdersPerSlot !== undefined &&
          remainingSeats < MINIMUM_REMAINING_ORDERS_PER_SLOT &&
          item.numOrders !== 0 && (
            <RemainingPlaceText textColor={textSlotColor}>
              {remainingSeats === 0 ? noRemaingSeatsLabel : remainingSeatsLabel }
            </RemainingPlaceText>
          )}
      </Slot>
    );
  };

  const showList =
    !isBookingSelected ||
    (isBookingSelected &&
      !selectedBookingOffer?.fullyBooked &&
      selectedBookingOffer?.published &&
      !!slots?.length &&
      !bookingForDifferentPos);

  const showIcon =
    selectedItemIndex !== undefined &&
    currentOfferSlot &&
    (!isBookingSelected ||
      (isBookingSelected &&
        changeSelection &&
        !selectedBookingOffer?.fullyBooked &&
        selectedBookingOffer?.published &&
        !!slots?.length &&
        !bookingForDifferentPos));

  return (
    <Container testID={TestIDs.restaurantDetail.views.offerSlotsContainer}>
      {!isBookingSelected && (
        <Title>{I18n.t("restaurantDetail.cart.pickupTime")}</Title>
      )}
      <LocalDateContainer>
        <Description isDate>{date}</Description>
        <Description>{pointOfSaleName}</Description>
      </LocalDateContainer>
      {showList && (
        <FlatList
          columnWrapperStyle={{
            marginBottom: OFFER_SLOT_GAP,
          }}
          data={slots}
          renderItem={renderItem}
          keyExtractor={(item: any) => item?.initSlot}
          numColumns={OFFER_SLOT_LIST_COLUMNS}
        />
      )}

      <WarningMessageContainer>
        {showIcon ? <Sucess /> : <WarningOrange />}
        <WarningMessage>{warningMessage}</WarningMessage>
      </WarningMessageContainer>
    </Container>
  );
};
