import React, { useMemo, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Dimensions,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { GlobalState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { useDevices } from "@hooks";
import { Spacing, Colors } from "@constants";
import { Filter, Modify } from "@assets";
import { I18n } from "react-redux-i18n";
import { ModalTemplate } from "@atomic/templates";
import { Button, PreferredZone } from "@atomic";
import {
  FiltersViewModel,
  FiltersType,
  FILTERTYPES,
  ZonesType,
} from "@modules";
import { arraySortByName } from "@utils";

interface IProps {
  idHoldingView: string;
  enablePreferredZone: boolean;
  maximumPreferredZones: number;
}

// @ts-ignore
export const HomepageFilters: React.FC<IProps> = React.memo(
  ({ idHoldingView, enablePreferredZone, maximumPreferredZones }) => {
    const dispatch = useDispatch();

    const windowHeight = Dimensions.get("window").height;
    const [isMobile] = useDevices();
    const {
      brandTheme,
      offerTypes,
      zones,
      posTypes,
      filters,
      preferredZones,
    } = useSelector((state: GlobalState) => ({
      brandTheme: state.brandTheme.brandTheme,
      offerTypes: state.filters.offerTypes,
      posTypes: state.filters.posTypes,
      zones: state.filters.zones,
      initialPosCards: state.filters.posCards || [],
      filters: state.filters.filters || [],
      preferredZones: state.preferredZones.preferredZones || [],
    }));

    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
    const [filtersCountModal, setFiltersCountModal] = useState<number>(
      filters.length
    );
    const [filtersCountHomepage, setFiltersCountHomepage] = useState<number>(
      filters.length
    );
    const [selectedFilters, setSelectedFilters] = useState<FiltersType[]>(
      filters
    );
    const [
      showPreferredZonesModal,
      setShowPreferredZonesModal,
    ] = useState<boolean>(false);
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [prefZones, setPrefZones] = useState<any[]>(
      preferredZones.map(({ name }) => ({ name }))
        ?.filter(eachPrefZone => zones.some(eachZone => eachZone.name === eachPrefZone.name)) || []
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disableModalClose, setDisableModalClose] = useState<boolean>(false);

    const styles = useMemo(
      () => _styles(isMobile, windowHeight, brandTheme),
      [isMobile, windowHeight, brandTheme]
    );

    const filtersVM = new FiltersViewModel();

    useEffect(() => {
      if (filters.length === 0 && selectedFilters.length !== 0) {
        clearFilters();
        setFiltersCountHomepage(0);
      }
    }, [filters]);

    const clearFilters = () => {
      setFiltersCountModal(0);
      setSelectedFilters([]);
    };
    const clearModalFilters = () => {
      setFiltersCountModal(0);
      setSelectedFilters([]);
    };

    const onClose = (selectedZones?: ZonesType[]) => {
      if (disableModalClose) {
        return;
      }

      if (showPreferredZonesModal) {
        if (!!selectedZones && selectedZones?.length !== undefined) {
          setPrefZones(selectedZones);
        }

        setShowPreferredZonesModal(false);
      } else {
        if (filters.length !== selectedFilters.length) {
          setSelectedFilters(filters);
          setFiltersCountModal(filters.length);
        }

        setShowFiltersModal(false);
      }
    };

    const isSelected = (type: string, value: any) => {
      const itemIndex = filtersVM.findFilterIndex({
        selectedFilters,
        type,
        value,
      });
      return itemIndex !== -1;
    };

    const onTouchablePress = (type: string, value: any) => {
      const newValue = { type, value };

      const itemIndex = filtersVM.findFilterIndex({
        selectedFilters,
        type,
        value,
      });

      if (itemIndex === -1) {
        const updatedArray = [...selectedFilters, newValue];
        setSelectedFilters(updatedArray);
        setFiltersCountModal(filtersCountModal + 1);
      } else {
        const updatedArray = [...selectedFilters];
        updatedArray.splice(itemIndex, 1);

        setSelectedFilters(updatedArray);
        setFiltersCountModal(filtersCountModal - 1);
      }
    };

    const applyFilters = async (
      filters: FiltersType[],
      homePageCount: number
    ) => {
      setIsLoading(true);
      await filtersVM.filterCards({
        filters,
        dispatch,
      });
      setFiltersCountHomepage(homePageCount);
      setIsLoading(false);
    };

    const submitFilters = async () => {
      await applyFilters(selectedFilters, filtersCountModal);
      setShowFiltersModal(false);
    };

    const onPreferredZoneSelect = async ({
      updatedZones,
      updatedPreferredZones,
    }: {
      updatedZones: ZonesType[];
      updatedPreferredZones: ZonesType[];
    }) => {
      let newFilters: FiltersType[] = [];
      let updatedFilterArray = [...selectedFilters];

      updatedZones.forEach((eachSelectedZone) => {
        const newFilter = { type: "posZone", value: eachSelectedZone.name };
        // @ts-ignore
        const itemIndex = filtersVM.findFilterIndex({
          selectedFilters: updatedFilterArray,
          type: newFilter.type,
          value: newFilter.value,
        });

        // used to control if we should add the preferred zones to the filters or not
        // ex. if a preferred zone is deselected from the filters and we remove it from the preferred zones it shouldn't add it to the filters
        const existsInPreferredZones = updatedPreferredZones.some(
          (eachNewPreferredZone) =>
            eachNewPreferredZone.name === eachSelectedZone.name
        );

        //!  AS OF TODAY (07/01/2025) IF WE REMOVE A PREFERRED ZONE THAT IS SELECTED AS A FILTER WE NEED TO REMOVE IT FROM THE FILTERS AS WELL
        // this might change in the future, to do so change the "else if" below

        // add the zones that aren't already selected to the filters
        if (itemIndex === -1 && existsInPreferredZones) {
          newFilters = [...newFilters, newFilter];
        } else if (itemIndex !== -1 && !existsInPreferredZones) {
          updatedFilterArray.splice(itemIndex, 1);
        }
      });

      updatedFilterArray = [...updatedFilterArray, ...newFilters];

      setFiltersCountModal(updatedFilterArray.length);
      setSelectedFilters(updatedFilterArray.sort(arraySortByName));
    };

    const zonesToShow =
      enablePreferredZone && prefZones.length > 0
        ? zones.filter(
            (eachZone) =>
              prefZones?.findIndex(({ name }) => name === eachZone.name) === -1
          )
        : zones;

    const FilterComp = () => (
      <View
        style={styles.modalContainer}
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }) => {
          setModalHeight(height);
        }}
      >
        <View style={styles.header}>
          <Text style={styles.modalTitle}>
            {I18n.t("filters.title")} ({filtersCountModal})
          </Text>
          <Text style={styles.unselectFilter} onPress={clearModalFilters}>
            {I18n.t("filters.unselectAll")}
          </Text>
        </View>
        <ScrollView
          style={{ flexGrow: 0 }}
        >
          {
            // offer types
          }
          <View style={styles.filterSection}>
            <Text style={styles.filterTitle}>
              {I18n.t("filters.offerTypes")}
            </Text>
            <View style={styles.cardsContainer}>
              {offerTypes.map((eachOfferType: any, index: number) => (
                <TouchableOpacity
                  key={`Filter_OffeZonesAndTypes_1_${index}`}
                  style={[
                    styles.filterCard,
                    isSelected(FILTERTYPES.OFFERTYPE, eachOfferType.type) &&
                      styles.filterCardSelected,
                  ]}
                  onPress={(): void =>
                    onTouchablePress(FILTERTYPES.OFFERTYPE, eachOfferType.type)
                  }
                >
                  <Text
                    style={[
                      styles.filterCardText,
                      isSelected(FILTERTYPES.OFFERTYPE, eachOfferType.type) &&
                        styles.filterCardTextSelected,
                    ]}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {eachOfferType.value}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>
          {
            // zones
          }
          <View style={styles.filterSection}>
            <Text style={styles.filterTitle}>{I18n.t("filters.zones")}</Text>
            {enablePreferredZone && (
              <View style={styles.preferredZonesContainer}>
                <Text style={styles.filterDescription}>
                  {prefZones.length === 1
                    ? I18n.t("preferredZones.title")
                    : I18n.t("preferredZones.titlePlural")}
                </Text>
                <View style={styles.cardsContainer}>
                  {prefZones.map(
                    (
                      eachPreferredZone: {
                        id: string;
                        name: string;
                      },
                      index: number
                    ) => (
                      <TouchableOpacity
                        key={`Filter_OffeZonesAndTypes_1_${index}`}
                        style={[
                          styles.filterCard,
                          isSelected(
                            FILTERTYPES.ZONE,
                            eachPreferredZone.name
                          ) && styles.filterCardSelected,
                        ]}
                        onPress={(): void =>
                          onTouchablePress(
                            FILTERTYPES.ZONE,
                            eachPreferredZone.name
                          )
                        }
                      >
                        <Text
                          style={[
                            styles.filterCardText,
                            isSelected(
                              FILTERTYPES.ZONE,
                              eachPreferredZone.name
                            ) && styles.filterCardTextSelected,
                          ]}
                          numberOfLines={1}
                          ellipsizeMode="tail"
                        >
                          {eachPreferredZone.name}
                        </Text>
                      </TouchableOpacity>
                    )
                  )}
                  <TouchableOpacity
                    key={`Filter_PreferredZonesEdit`}
                    onPress={() => setShowPreferredZonesModal(true)}
                    style={styles.modifyIconContainer}
                  >
                    <Modify height={40} />
                  </TouchableOpacity>
                </View>
              </View>
            )}
            <View style={styles.cardsContainer}>
              {zonesToShow.map(
                (
                  eachZone: {
                    id: string;
                    name: string;
                  },
                  index: number
                ) => (
                  <TouchableOpacity
                    key={`Filter_OffeZonesAndTypes_1_${index}`}
                    style={[
                      styles.filterCard,
                      isSelected(FILTERTYPES.ZONE, eachZone.name) &&
                        styles.filterCardSelected,
                    ]}
                    onPress={(): void =>
                      onTouchablePress(FILTERTYPES.ZONE, eachZone.name)
                    }
                  >
                    <Text
                      style={[
                        styles.filterCardText,
                        isSelected(FILTERTYPES.ZONE, eachZone.name) &&
                          styles.filterCardTextSelected,
                      ]}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {eachZone.name}
                    </Text>
                  </TouchableOpacity>
                )
              )}
            </View>
          </View>
          {
            // pos types
          }
          <View style={styles.filterSectionLast}>
            <Text style={styles.filterTitle}>{I18n.t("filters.posTypes")}</Text>
            <View style={styles.cardsContainer}>
              {posTypes.map((eachPos: string, index: number) => (
                <TouchableOpacity
                  key={`Filter_OffeZonesAndTypes_1_${index}`}
                  style={[
                    styles.filterCard,
                    isSelected(FILTERTYPES.POSTYPE, eachPos) &&
                      styles.filterCardSelected,
                  ]}
                  onPress={(): void =>
                    onTouchablePress(FILTERTYPES.POSTYPE, eachPos)
                  }
                >
                  <Text
                    style={[
                      styles.filterCardText,
                      isSelected(FILTERTYPES.POSTYPE, eachPos) &&
                        styles.filterCardTextSelected,
                    ]}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {eachPos}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </ScrollView>
        <Button
          onPress={submitFilters}
          label={I18n.t("filters.submitBtn")}
          styleButton={styles.submitBtn}
          loading={isLoading}
        />
      </View>
    );

    return (
      <View style={styles.container}>
        <Pressable
          style={styles.homepageFilter}
          onPress={() => setShowFiltersModal(true)}
        >
          <>
            <View style={styles.iconTitleContainer}>
              <View style={styles.iconContainer}>
                <Filter />
              </View>
              <Text style={styles.title}>
                {I18n.t("filters.title")} ({filtersCountHomepage})
              </Text>
            </View>
          </>
        </Pressable>
        <ModalTemplate
          isOpen={showFiltersModal}
          handleClose={onClose}
          closeOnClickOutside={true}
          isCenter={false}
          isFullScreen={true}
          paddingHorizontal={0}
          paddingVertical={0}
          style={styles.modal}
          isMobile={isMobile}
          bodyStyle={styles.modalBody}
        >
          {showPreferredZonesModal ?
            <PreferredZone 
              idHoldingView={idHoldingView}
              preferredZones={prefZones}
              zones={zones}
              maximumPreferredZones={maximumPreferredZones}
              modalHeight={modalHeight}
              onClose={onClose}
              shouldUpdateZones
              onPreferredZoneSelect={(selectedZone) => onPreferredZoneSelect(selectedZone)}
              setDisableModalClose={setDisableModalClose}
            />
          : FilterComp()}
        </ModalTemplate>
      </View>
    );
  }
);

const _styles = (
  isMobile: boolean,
  height: number,
  brandTheme: any,
) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      paddingLeft: Spacing.L,
      marginTop: isMobile ? Spacing.S : 0,
      marginBottom: Spacing.M,
      justifyContent: "flex-end",
      width: "100%",
    },
    iconTitleContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    homepageFilter: {
      flexDirection: "column",
    },
    iconContainer: {
      marginRight: 5,
    },
    title: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: isMobile ? 16 : 22
    },
    modalTitle: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 22
    },
    modal: {
      width: isMobile ? Dimensions.get("window").width : "auto",
      maxHeight: isMobile ? height : "100%",
      flexGrow: 0,
    },
    modalBody: {
      bottom: isMobile ? 0 : "auto",
      maxWidth: isMobile ? Dimensions.get("window").width : "50%",
      justifyContent: isMobile ? "flex-end" : "center",
    },
    modalContainer: {
      padding: Spacing.L,
      paddingBottom: isMobile ? 0 : Spacing.L,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: "100%"
    },
    header: {
      height: 30,
      marginBottom: 30,
      width: "100%",
      alignItems: "center",
      top: 0,
    },
    filterSection: {
      marginBottom: 30,
      width: "100%",
    },
    filterSectionLast: {
      width: "100%",
    },
    filterTitle: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 13,
      textAlign: isMobile ? 'left' : "center",
    },
    filterDescription: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      fontSize: 13,
      textAlign: isMobile ? 'left' : "center",
    },
    unselectFilter: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      fontSize: 13,
      textDecorationLine: 'underline',
    },
    filterTitleSelected: {
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
    cardsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: isMobile ? 'flex-start' : "center",
    },
    filterCard: {
      maxWidth: isMobile ? "90%" : 300,
      height: 40,
      borderRadius: 8,
      borderWidth: 0.5,
      paddingVertical: 5,
      paddingHorizontal: 15,
      marginTop: 10,
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    filterCardSelected: {
      backgroundColor: brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
      borderWidth: 0,
    },
    filterCardText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      fontSize: 16,
    },
    filterCardTextSelected: {
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
    modifyIconContainer: {
      marginTop: 10,
    },
    submitBtn: {
      width: isMobile ? Dimensions.get('window').width : '200px',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: 60,
      borderRadius: isMobile ? 0 : 8,
      marginTop: 10,
    },
    underline: {
      height: 3,
      backgroundColor: Colors.foodiBlack,
      width: "100%",
    },
    preferredZonesContainer: {
      marginBottom: Spacing.M
    }
  });
